.upgrade-tag {
  width: 100px;
  text-align: "center";
  border-radius: 14px;
  margin-left: 10px;
  padding: -1px 7px;
  position: "relative";
  top: -5px;
  padding-top: 2px;
}

.popover-color .ant-popover-inner {
  background-color: #999;
  color: #fff;
}
.popover-color .ant-popover-title {
  color: #999;
}
