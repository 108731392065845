.logo {
  height: 24px;
  width: 120px;
  margin: 12px 0px;
  cursor: pointer;
  padding-left: 24px;
}

.wrapper-layout-header {
  position: fixed;
  width: 100%;
  padding: 0;
  line-height: 48px !important;
  height: 48px;
  z-index: 1001;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  &.light {
    @include header-theme("light");
  }

  &.dark {
    @include header-theme("dark");
  }

  .header-flex {
    @include flex-box(center, space-between);
    max-width: 98vw;
    padding-left: 0;
    max-height: 48px;
  }
}
.wrapper-sub-header {
  width: 100vw;
  max-width: 100%;
  padding: 0;
  margin-top: 53px;
  height: 53px;
}
.arrow-left {
  font-size: 11px;
  margin-bottom: 1px;
}
.breadcrumbs {
  margin-left: 0;
  height: 24px;
  font-weight: 600;
  font-size: 1.2em;
  a {
    height: auto;
  }
}
