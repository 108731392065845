.row-wrapper {
  & .react-chatbot-kit-user-chat-message-arrow {
    top: 18%;
  }
  & .react-chatbot-kit-chat-input:focus {
    outline: none;
    border: 1px solid #d8d8d8;
  }
  & .react-chatbot-kit-chat-bot-message {
    // width: 80% !important;
    word-wrap: break-word;
    white-space: normal;
    margin-left: 1px;
    max-width: 80%;
    width: 80%;
    //white-space: pre-wrap;
    & span {
      color: #333333;
    }
  }

  & .react-chatbot-kit-chat-btn-send {
    width: 20%;
    @include toggle-interaction($is-disabled: true);

    &.enabled {
      @include toggle-interaction($is-disabled: false);
    }
  }

  & .react-chatbot-kit-chat-input {
    padding: 3px;
    @include toggle-interaction($is-disabled: true);

    &.enabled {
      @include toggle-interaction($is-disabled: false);
    }
  }
  & .react-chatbot-kit-user-chat-message {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #585858;
    font-weight: medium;
    position: relative;
    text-align: left;
    word-wrap: break-word;
    white-space: normal;
    max-width: 80%;
    width: 80%;
    overflow-wrap: break-word;
  }
  & .react-chatbot-kit-chat-container {
    height: 100% !important;
    width: 100% !important;
  }
  & .react-chatbot-kit-chat-inner-container {
    height: 100% !important;
    position: absolute;
    width: 100%;
  }
  & .react-chatbot-kit-chat-message-container {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: 94% !important;
  }
  & .react-chatbot-kit-chat-bot-message-arrow {
    color: #333333;
    background-color: white;
  }
  & .react-chatbot-kit-chat-btn-send {
    background-color: transparent !important;
    border-top: 1px solid #d8d8d8 !important;
  }
  & .react-chatbot-kit-chat-btn-send-icon {
    fill: #333333 !important;
  }

  & .ant-spin-nested-loading {
    width: 100%;
    min-width: 100%;
  }
  & .ant-spin-nested-loading .ant-spin-container {
    height: 100%;
  }
  & .jLGDuY {
    min-height: 95%;
    min-width: 100%;
  }
}
.chat-loading {
  height: 100%;
  & .ant-spin-nested-loading .ant-spin-container {
    height: 100%;
  }
  & .ant-spin-container {
    height: 100%;
  }
}
.chat-loading .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}
