.popconfirm-title {
  max-width: 300px !important;
  white-space: normal;
  word-wrap: break-word;
  flex-wrap: wrap;
  display: flex;
}

.wrapper-popup {
  position: absolute;
  z-index: 1000;
  max-width: 200px !important;
}
