@mixin flex-box($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}
@mixin toggle-interaction($is-disabled: false) {
  pointer-events: if($is-disabled, none, auto);
  opacity: if($is-disabled, 0.5, 1);
  cursor: if($is-disabled, not-allowed, pointer);
  background-color: if($is-disabled, #f0f0f0, #fff);
}
@mixin header-theme($theme) {
  @if $theme == "light" {
    border-bottom: 1px solid #e8e8e8;
    background-color: #ffffff;
  } @else if $theme == "dark" {
    border-bottom: 1px solid #808080;
    background-color: #141414;
  }
}
