.tooltip-content {
  background: #fff;
  color: #000;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  max-width: 100%;
  font-size: 10px;
}
.g6-tooltip {
  max-width: 35%;
  word-wrap: break-word;
  white-space: normal;
}
#description-bowtie * {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px !important;
}
