.a9s-annotation.long {
  stroke-width: 4;
  stroke: red;
  background-color: red;
}

.a9s-hover-tooltip {
  position: absolute;
  background-color: #fff;
  padding: 8px 12px;
  pointer-events: none;
  border-radius: 3px;
  box-shadow: 0 0 17px rgba(0, 0, 0, 0.4);
}

#image-annot-types {
  background-color: transparent;
}

#image-annot-types > span > span > svg {
  width: 90%;
  height: 90%;
  overflow: visible;
}

#image-annot-types > span > span > svg * {
  stroke-width: 5px;
  fill: none;
  stroke: rgba(0, 0, 0, 0.5);
}

#image-annot-types > span > span g.handles circle {
  stroke-width: 4px;
  fill: #fff;
  stroke: #000;
}
