.bpmn-toolbar-static {
  & .flex-center {
    @include flex-box(center, center);
  }
  & .button {
    width: 95%;
    font-size: 12px;
    margin: 0 auto;
  }
}
.djs-label {
  max-height: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
